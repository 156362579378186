<template>
  <div id="contentUs">
    <t-header :nav-light="false"></t-header>
    <section class="bg-half bg-light d-table w-100" style="background: url('media/images/images/contact-detail.jpg') center center;">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Napolyon.com Gizlilik Politikası</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item"><a @click="$router.push({name:'user.index'})">{{$t('index.home')}}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Napolyon.com Gizlilik Politikası</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>  <!--end col-->
        </div><!--end row-->
      </div> <!--end container-->
    </section><!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- questions section begin -->
    <div class="questions-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12">
            <p><strong>Son düzenlenme tarihi: 13.11.2023</strong></p>
              <p>
                Napolyon.com projesi için tahsis edilmiş www.napolyon.com alan adlı web sitesinin (kısaca "Web Sitesi" olarak anılacaktır) tüm hakları Napolyon Reklam ve Danışmanlık Hizmetleri Ticaret Anonim Şirketi'ne (kısaca "Napolyon.com" olarak anılacaktır) aittir. Napolyon.com, Web Sitesi üzerinden kullanıcılar tarafından kendisine elektronik ortamda iletilen kişisel bilgileri, üyeleri ile yaptığı "Napolyon.com Üyelik Sözleşmesi" ile belirlenen amaçlar ve kapsam dışında üçüncü kişilere açıklamayacaktır.
              </p><p>
              Sistemle ilgili sorunların tanımlanması ve Web Sitesi'nde çıkabilecek sorunların ivedilikle giderilebilmesi için, Napolyon.com, gerektiğinde kullanıcıların IP adresini tespit etmekte ve bunu kullanmaktadır. IP adresleri, kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da kullanılabilir.
            </p><p>
              Üyelik veya Web Sitesi üzerindeki çeşitli formların doldurulması ve oylamaların yapılması suretiyle kullanıcıların kendileriyle ilgili bir takım kişisel bilgileri (ad-soyad, telefon, adres veya e-posta adresleri gibi) Napolyon.com'a vermeleri gerekmektedir. Napolyon.com, "Napolyon.com Üyelik Sözleşmesi" ile belirlenen amaçlar ve kapsam dışında da, talep edilen bilgileri Napolyon.com veya işbirliği içinde olduğu kişiler tarafından/vasıtasıyla doğrudan pazarlama yapmak amacıyla kullanabilir. Kişisel bilgiler, gerektiğinde kullanıcıyla temas kurmak için de kullanılır. Napolyon.com tarafından talep edilen bilgiler veya kullanıcı tarafından sağlanan bilgiler veya Napolyon.com web sitesi üzerinden yapılan işlemlerle ilgili bilgiler; Napolyon.com ve işbirliği içinde olduğu kişiler tarafından, "Napolyon.com Üyelik Sözleşmesi" ile belirlenen amaçlar ve kapsam dışında da, kullanıcının kimliği ifşa edilmeden çeşitli istatistiksel değerlendirmeler, veritabanı oluşturma ve pazar araştırmalarında kullanılabilir.
            </p><p>
              Napolyon.com, Web Sitesi dahilinde başka sitelere link verebilir. Napolyon.com, link vasıtasıyla erişilen sitelerin gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır.
            </p><p>Kişisel Verilerin Korunması Kanunu’nun 11.maddesi uyarınca, kullanıcılar tarafımıza başvurarak kendileri ile ilgili; kişisel veri işlenip işlenmediğini öğrenme, işlenmişse buna ilişkin bilgi talep etme, işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, kişisel verilerin aktarıldığı üçüncü kişileri bilme ve kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme haklarına sahiptir
            </p><p>
              Kişisel bilgiler; kişi adı-soyadı, adresi, telefon numarası, e-posta adresi ve kullanıcıyı tanımlamaya yönelik her türlü bilgiyi içermekte olup, kısaca "Gizli Bilgiler" olarak anılacaktır. Napolyon.com, işbu gizlilik politikasında aksi belirtilmedikçe kişisel ilgilerden herhangi birini Napolyon.com'un işbirliği içinde olmadığı şirketlere ve üçüncü kişilere açıklamayacaktır. Aşağıda belirtilen sınırlı durumlarda Napolyon.com, işbu "Gizlilik Politikası" hükümleri dışında kullanıcılara ait bilgileri üçüncü kişilere açıklayabilir. Bu durumlar sınırlı sayıda olmak üzere;
            </p><p>
              <i class="fa fa-arrow-circle-o-right"></i> Yetkili hukuki otoriteler tarafından çıkarılan ve yürürlükte olan Kanun, Kanun Hükmünde Kararname, Yönetmelik v.b. gibi hukuk kurallarının getirdiği zorunluluklara uyulmasının;
            </p><p>
              <i class="fa fa-arrow-circle-o-right"></i> Napolyon.com'un kullanıcılarla akdettiği "Napolyon.com Üyelik Sözleşmesi"nin ve diğer sözleşmelerin gereklerinin yerine getirilmesi ve bunların uygulamaya konulmasının;
            </p><p>
              <i class="fa fa-arrow-circle-o-right"></i> Yetkili idari ve adli otorite tarafından usulüne göre yürütülen bir araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla ilgili bilgi talep edilmesinin ve
            </p><p>
              <i class="fa fa-arrow-circle-o-right"></i> Kullanıcıların hakları veya güvenliklerini korumak için bilgi verilmesinin gerekli olduğu hallerdir.
            </p><p>
              Napolyon.com, kişisel bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir kısmının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmektedir. Napolyon.com'un gerekli bilgi güvenliği önlemlerini almasına karşın Web Site'sine ve sisteme yapılan saldırılar sonucunda gizli bilgilerin zarar görmesi veya üçüncü kişilerin eline geçmesi durumunda Napolyon.com'un herhangi bir sorumluluğu olmayacaktır.
            </p><p>
              Napolyon.com, kullanıcılar ve kullanıcıların Web Sitesi'ni kullanımına dair bilgilerini teknik bir iletişim dosyasını (Kurabiye-Cookie) kullanarak elde edebilir. Bahsi geçen teknik iletişim dosyaları, ana bellekte saklanmak üzere bir web sitesinin kullanıcının tarayıcısına (browser) gönderdiği küçük metin dosyalarıdır. Teknik iletişim dosyası bir web sitesi hakkında durum ve tercihleri saklayarak internetin kullanımını kolaylaştırır. Teknik iletişim dosyası, bir web sitesini kaç kişinin kullandığı, bir kişinin web sitesini hangi amaçla, kaç kez ziyaret ettiği ve sitede ne kadar kaldığı hakkında istatistiksel bilgileri elde etmek amacıyla kullanılabilir. Ayrıca kullanıcılar için özel tasarlanmış kullanıcı sayfalarından dinamik olarak izinli pazarlama mesajı ve içerik üretilmesine yardımcı olur. Teknik iletişim dosyası, ana bellekte veya kullanıcıların e-posta adresinden veri veya başkaca herhangi bir kişisel bilgi almak için oluşturulmamıştır. Tarayıcıların pek çoğu başta teknik iletişim dosyasını kabul eder biçimde tasarlanmıştır, ancak kullanıcılar dilerse teknik iletişim dosyasının gelmemesi veya teknik iletişim dosyası gönderildiğinde ikaz verilmesini sağlayacak biçimde ayarları değiştirebilirler.
            </p><p>Web Sitemiz diğer web sitelerine link vermektedir. Gizlilik Politikamız'da yer alan taahhütlerimiz sadece web sitemiz içerisinde geçerlidir ve diğer web sitelerini kapsamamaktadır. Web Sitemiz'den link ile gidilecek diğer web sitelerindeki kullanım ile ilgili o sitelere ait gizlilik güvencesi ve kullanım şartları geçerlidir. Web sitemizden reklam, banner, içerik maksatlı veya başka bir maksat ile geçilen diğer web sitelerinin bilgi kullanımı, etik ilkeleri, gizlilik prensipleri, nitelik ve servis kalitesi ile bu sitelerde oluşabilecek herhangi bir maddi / manevi zarar ve kayıplardan Napolyon Reklam ve Danışmanlık Hizmetleri Ticaret A.Ş. sorumlu değildir.
            </p><p>
              Napolyon.com tarafından Web Sitesi dahilinde düzenlenen periyodik anketlere cevap veren kullanıcılardan talep edilen bilgiler, Napolyon.com ve işbirliği içindeki kişiler tarafından bu kullanıcılara doğrudan pazarlama yapmak, istatistiki analiz yapmak ve veritabanı oluşturmak amacıyla kullanılmaktadır.
            </p><p>
              Napolyon.com, işbu "Gizlilik Politikası" hükümlerini dilediği zaman Web Sitesi'nde yayınlamak suretiyle tek taraflı olarak değiştirebilir. Napolyon.com'in değişiklik yaptığı "Gizlilik Politikası" hükümleri Web Sitesi'nde yayınlandığı tarihte yürürlülük kazanır.
            </p>

              <hr>

              <h1 class="turuncu-baslik">Privacy</h1>
              <p>Last review date 13.11.2023</p>
              <p>
                All the rights on the Website dedicated to the Napolyon.com project under the domain name "www.napolyon.com" (hereinafter will be referred to as the "WebSite") belong to Napolyon Reklam ve Danışmanlık Hizmetleri Ticaret Anonim Şirketi (hereinafter will be referred to as "Napolyon.com"). Napolyon.com will not reveal to third parties the personal information electronically transmitted by the users from the Website except for the purposes and scope identified by the "Napolyon.com Membership Agreement" that it has made with its members.
              </p><p>
              Napolyon.com, when necessary for the purposes of defining the problems about the system and urgently troubleshooting for the troubles that might occur in the Website, may identify the IP addresses of the users and use them. IP addresses may as well be used to generally define the users and collect comprehensive demographic information.
            </p><p>
              It is required for the users to provide Napolyon.com with some personal information (name-surname, company information, telephone number, address, email address etc.) about themselves by way of membership or filling some forms or polls on the Website. Napolyon.com can use such information for marketing that would be made directly by Napolyon.com or by persons in cooperation with Napolyon.com outside the purposes and scope defined in Napolyon.com Membership Agreement, as well. Personal information may as well be used to contact the user, when necessary. The information requested by Napolyon.com or the information provided by the user or the information about the operations made via the Website can be used by Napolyon.com and by persons in cooperation with Napolyon.com, outside the purposes and scope defined in Napolyon.com Membership Agreement; for the purposes of making some statistical evaluations, composing a database or market research; without disclosing the users' identities.
            </p><p>
              Napolyon.com can, on the Website, provide links to other websites. Napolyon.com holds no responsibility for the privacy applications and contents of the sites accessed by such links.
            </p><p>
              Personal information include the name, surname, address, telephone number, email address and all kinds of information to identify the user and will be cited as "Confidential Information". Napolyon.com, unless stated otherwise in this "Privacy Policy", will not reveal any of the users' personal information to the companies that are not in cooperation with Napolyon.com or to third parties. In the limited situations identified below, Napolyon.com may, outside the provisions of this "Privacy Policy" reveal the user's information to third parties. Such limited number of situations are;
            </p><p>
              <i class="fa fa-arrow-circle-o-right"></i> To comply with the obligations arising from legal rules made by the competent legal authorities and are in force such as Codes, Decree laws, Regulations etc.;
            </p><p>
              <i class="fa fa-arrow-circle-o-right"></i> To comply with the requirements of the "Napolyon.com Membership Agreement" and other agreements between Napolyon.com and the users and to put them into practice;
            </p><p>
              <i class="fa fa-arrow-circle-o-right"></i> In the event that information concerning the users is requested by the competent administrative and judicial authorities for the purpose of duly executing an investigation or an enquiry;
            </p><p>
              <i class="fa fa-arrow-circle-o-right"></i> The circumstances in which giving information is necessary for the protection of the user's rights and security.
            </p><p>
              Napolyon.com undertakes to keep the personal information private and confidential, to deem this as an obligation of secrecy and to take all the necessary measures and to show the necessary care to provide and maintain secrecy and to avoid the Confidential Information as a whole and partly to enter the public domain or to be used without authority or to be disclosed to any third parties. In the event that the Confidential Information is damaged or passed into the hands of third parties by any attacks to the Web Site or to the system that occurred, despite all the necessary information security measures taken by Napolyon.com, Napolyon.com shall have no responsibility.
            </p><p>
              Napolyon.com can obtain information about the users and the users' usage of the Website by using a technical communication file (Cookie) as well. Such technical communication files are small text files sent by a website to a user's browser to be kept in the main memory. Technical communication file stores the conditions and choices about a website and eases the internet usage. Technical communication file facilitates to provide statistical information concerning the number of the users of the website, the purpose of the visit of a person of a website, the number and duration of the visits and to dynamically produce advertisements and content from user pages designed specifically for the users. Technical communication file is not designed to collect data or any other personal information from the main memory or an email account. Most of the browsers are initially designed to accept technical communication files, however the users, if wished, can change the settings so that no technical communication file is received or an alert would appear when a technical communication file is sent.
            </p><p>
              Information requested by Napolyon.com from the users who reply to the periodical surveys on the Website are used by Napolyon.com and persons in cooperation with Napolyon.com for the purposes of direct marketing to such users, statistical analysis and composition of a database.
            </p><p>
              Napolyon.com’s structure is in compliance with the "Controlling the Assault of Non-Solicited Pornography and Marketing Act 2003 of USA" and the "EU Data Protection Directive no 95/46/EC", although it is not bound by both of the legislation.
            </p><p>
              Napolyon.com can amend the provisions of this "Privacy Policy" anytime by publishing the change on the Website. The amendments on the "Privacy Policy" become effective as they are published on the Website.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- questions section end -->

    <!-- Start Contact -->
    <t-up-footer></t-up-footer>
    <t-footer></t-footer>
  </div>

</template>

<script>
import Footer from "@/view/layouts/Footer";
import upFooter from "@/view/layouts/upFooter";
import Header from "@/view/layouts/Header";
import feather from 'feather-icons';
export default {
  name: "Privacy",
  components: {
    't-footer': Footer,
    't-up-footer': upFooter,
    't-header': Header,
  },
  mounted() {
    window.scrollTo(0,0);
    feather.replace();
  },
}
</script>

<style scoped>

</style>